/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add  to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import "../css/tailwind.css";
import "../css/react_dates_overrides.less";
import "../css/application.less";
import mixpanel from "mixpanel-browser";
import '@hotwired/turbo-rails'
//read data-attributes from the DOM whit data-mixpanel-token

var token = document
  .getElementById("mixpanel-tracker")
  .getAttribute("data-mixpanel-token");

mixpanel.init(token);

Turbo.session.drive = false

// Support component names relative to this directory:
const componentRequireContext = require.context('components', true)
const ReactRailsUJS = require('react_ujs')
ReactRailsUJS.useContext(componentRequireContext)

// Prevent double mount on page load
ReactRailsUJS._firstTurboLoadSkipped = false
ReactRailsUJS.handleEvent('turbo:frame-load', () => {
  if (ReactRailsUJS._firstTurboLoadSkipped) ReactRailsUJS.handleMount()
  ReactRailsUJS._firstTurboLoadSkipped = true
})

// Unmount components and call cleanup functions after Turbo navigations
ReactRailsUJS.handleEvent('turbo:before-frame-render', (e) => {
  ReactRailsUJS.unmountComponents(e.target)
})

ReactRailsUJS.handleEvent('turbo:load', () => {
  if (ReactRailsUJS._firstTurboLoadSkipped) ReactRailsUJS.handleMount()
  ReactRailsUJS._firstTurboLoadSkipped = true
})

// Unmount components and call cleanup functions after Turbo navigations
ReactRailsUJS.handleEvent('turbo:before-render', (e) => {
  ReactRailsUJS.unmountComponents(e.target)
})
